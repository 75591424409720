/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/plugins/axios'
import { Trainee } from '@/api/interfaces/trainee'
import { PrintDocumentType } from '@/api/enums/printDocumentType'

export interface generateState {
  idTrainingSession: string | null
  idSessionDocuments: string | null,
  typeDocument: PrintDocumentType | null,
  percentage: number | null,
  mentionClimat: Array<Trainee>,
}

@Module({ name: 'import', namespaced: true })
class generateModule extends VuexModule implements generateState {
  public idTrainingSession: string | null = null
  public idSessionDocuments: string | null = null
  public typeDocument: PrintDocumentType | null = null
  public percentage: number | null = 0
  public mentionClimat = []

  @Mutation
  public setIdTrainingSession (id: string): void {
    this.idTrainingSession = id
  }

  @Mutation
  public setIdSessionDocuments (id: string): void {
    this.idSessionDocuments = id
  }

  @Mutation
  public setTypeDocument (type: PrintDocumentType): void {
    this.typeDocument = type
  }

  @Mutation
  public setMentionClimat (trainee: Trainee): void {
    const index = this.mentionClimat.findIndex((t: Trainee) => t.id === trainee.id)
    if (index === -1) {
      this.mentionClimat.push(trainee as never)
    } else {
      this.mentionClimat.splice(index, 1)
    }
  }

  @Mutation
  public setPercentage (percentage: number): void {
    this.percentage = percentage
  }

  @Action({ rawError: true })
  public async launchGenerateDocument (params: { idSession: string, idLogisticSession: string, typeDocument: string, body: never}): Promise<string> {
    this.context.commit('setPercentage', 0)
    const response = await http.post(`print/training_sessions/${params.idSession}/logistic_sessions/${params.idLogisticSession}/documents_mass_generation/${params.typeDocument}`, params.body)
    this.context.commit('setIdTrainingSession', params.idSession)
    this.context.commit('setTypeDocument', params.typeDocument)
    this.context.commit('setIdSessionDocuments', response.data)
    return response.data
  }

  @Action({ rawError: true })
  public async getAdvancement (idSessionDocuments: string): Promise<string> {
    const response = await http.get(`api/training_session_documents/${idSessionDocuments}`)
    const percentage = Math.round(response.data.nbDocumentsGenerated / response.data.nbDocuments * 100) || 0
    this.context.commit('setPercentage', percentage)

    return response.data
  }

  @Action({ rawError: true })
  public addMentionClimat (trainee: Trainee): void {
    this.context.commit('setMentionClimat', trainee)
  }
}

export default generateModule
